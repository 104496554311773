<template>
    <!-- <div class="comp-single-box"> -->
    <div class="Goods_item" :class="`open-lv${item.level}`">
        <div class="Goods_pic">
            <img :src="item.cover || item.skins.cover" alt="" />
        </div>
        <div class="box-price">
            <div class="odds_percent">
                <!-- <div class="d-flex align-items-center"><img style="width: 0.2rem;margin-right: 0.05rem;"
                        src="@/assets/images/about/user/huobi.png" alt="">{{ item.bean || item.skins.bean }}</div> -->
                <div class="d-flex align-items-center">
                    <Cprice :price="item.bean || item.skins.bean" />
                </div>
            </div>
            <div class="dura_alias">
                概率:{{ item.odds_percent }}
            </div>
        </div>
        <p>{{ item.name || item.skins.name }}</p>
        <div class="" style="font-size: 0.14rem">
            {{ item.skins.dura_alias == "无" ? "" : item.skins.dura_alias }}
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
export default {
    props: {
        item: {}
    }
}
</script>

<style lang="scss">
// .comp-single-box {
.Goods_item {
    width: 100%;
    height: 100%;
    // height: 2.6rem;
    text-align: center;
    color: #fff;
    margin: 0.1rem;
    padding-bottom: 10px;
    // background-color: rgba($color: #000000, $alpha: 0.2);
    // background: url(../../assets/images/public/back_pub.jpg) no-repeat center;
    // background-size: contain;
    // border-radius: 6px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .box-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .odds_percent {
        font-size: 0.19rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
        box-sizing: border-box;
        color: #dea61f;
    }

    .dura_alias {
        text-align: left;
        padding-right: 10px;
        box-sizing: border-box;
        color: #fff;
        font-size: 0.15rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    .Goods_pic {
        width: 80%;
        height: 1.35rem;
        margin: 0.1rem auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;

        img {
            max-width: 100%;
            vertical-align: middle;
        }
    }

    p {
        width: 100%;
        padding: 0 3px;
        box-sizing: border-box;
        font-size: 0.14rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0.18rem;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #75cd66;
        font-size: 16px;
        margin: 5px 0 0;

        img {
            width: 20px;
        }
    }
}

// }
</style>