<template>
    <div :style="`width:${width}rem`" class="comp-probability" :class="{ 'comp-probability1': width }">
        <div class="prob-item" v-for="(item) in list" :key="item.id">
            <img :src="proBList[item.level * 1 - 1]" alt="" />
            <div style="width:0.6rem">{{ item.odds }}</div>
        </div>
    </div>
</template>

<script>
import {
    proBList,

} from "./index.js";
export default {
    props: {
        list: [],
        width: {
            type: [Number, String],
            default: null
        },
    },
    data() {
        return {
            proBList
        }
    }
}
</script>

<style lang="scss">
.comp-probability {
    // position: absolute;
    // right: 0.25rem;
    // top: 7.6rem;
    // width: 4.35rem;
    background: url(../../../assets/images/OpenBox/prob-bg.png) no-repeat;
    background-size: 100% 100%;
    width: fit-content;
    height: 0.51rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.17rem;
    font-weight: 400;
    padding: 0 0.26rem;
    box-sizing: border-box;
    color: #3399cc;
    margin-top: 0.2rem;

    img {
        width: 0.25rem;
        height: 0.23rem;
        margin-right: 0.03rem;
    }

    .prob-item {
        display: flex;
        align-items: center;
        margin-right: 0.1rem;
    }
}

.comp-probability1 {
    background: unset;
    flex-wrap: wrap;
    padding: 0;
    display: flex;
    align-content: center;
    justify-content: space-between;

    .prob-item {
        margin-bottom: 0.2rem;
        // margin-right: 0.2rem;
    }
}
</style>