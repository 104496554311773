// import {Message} from "element-ui";
var md5 = require("md5");
/**
 * 生成随机的字符串
 * */
let str = "tmskins.com&MessagePush*Key";
// 生成十六问的随机字符串
function randomString(len = 16) {
  //随机值
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
  //随机的长度
  var maxPos = $chars.length;
  //暂时定义为空的
  var str = "";
  //循环拼接
  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return str;
}

// 签名
function qianming(config) {
  // const{url,data.methed}=config
  // 提取url 由于get请求会有？后面的参数所以需要裁切一下
  let i = config.url.indexOf("?");
  // 保存url
  let url = "";
  // 如果有？ 裁切前面的
  if (i != -1) {
    url = config.url.slice(0, i);
  } else {
    // 没有直接等于这个url
    url = config.url;
  }
  let tiem = new Date();
  // 获取时间戳
  let t = (tiem.getTime() / 1000).toFixed(0);
  //整理数据
  let QM = {
    // 时间戳
    timestamp: t,
    // 随机字符串 32位
    nonce: randomString(32),
    // 签名
    secretKey: "KVEa5kxk5C4CihD6XUFIi81bNSID60714",
  };
  //整理成 可使用的数据传给后端
  // config.method.toUpperCase()请求转大写
  // url请求的域名
  // QM.timestamp 时间戳
  // QM.nonce 随机字符串 32位
  // config.method.toUpperCase()判断是不是GET请求   如果是 \n 否则 在判断 这个post请求体如果是真的就传输这个请求体否则 \n
  // QM.secretKey 签名
  let signature = `${config.method.toUpperCase()}\n/api${url}\n${
    QM.timestamp
  }\n${QM.nonce}\n${
    config.method.toUpperCase() == "GET"
      ? "\n"
      : config.data
      ? JSON.stringify(config.data) + "\n"
      : "\n"
  }${QM.secretKey}\n`;
  // 进行md5加密
  let encryption = md5(signature);
  // 然后拼接到字符串上
  let getUrl = {
    timestamp: QM.timestamp,
    nonce: QM.nonce,
    sign: encryption,
  };
  return getUrl;
}

// MD5加密
function md5jiami(client_id) {
  //随机字符串
  let randomStr = randomString();
  // 获取时间戳
  var timestamp = Date.parse(new Date());
  //  当前时间的时间戳
  let tiem = timestamp.toString().substr(0, 10);
  let app_id = "tmskins.com";
  let token = client_id + randomStr + tiem + app_id + str;
  //md5加密
  let md5Token = md5(token);
  return {
    randomStr,
    tiem,
    md5Token,
  };
}
export default {
  md5jiami,
  randomString,
  qianming,
};
